<template>
<div>
  <div class="container">
      <div class="row mt-4">
          <div class="col-lg-2"></div>
          <div class="col-lg-6">
<base-input required label="User Name">
                    <span class="validation-msg" v-if="v$.email.$error">
                      &nbsp;{{ v$.email.$errors[0].$message }}
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="email"
                    />
                  </base-input>
          </div>
          <div class="col-lg-3 mt-4">
              <button
                    @click="resetPassword()"
                    class="btn btn-success"
                    type="primary"
                    >Reset Password</button>
                  
          </div>
      </div>
        
  </div>
</div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ForgetPassword} from "../api/login"
import Swal from "sweetalert2";
export default {
 validations() {
     return {
        email: { required },
     }
 },
  data() {
    return {
      v$: useValidate(),
      email:''
    }
  },
  methods: {
    async resetPassword (){
      try{
const payload = {
            user_name:this.email
        }
        const data = await ForgetPassword(payload)
        console.log(data)            
        this.$router.push({
        name: "reset_Password",params: {user_name:this.email}});
  Swal.fire({
          title: "Success",
          text: "Email Send successfully",
          icon: "success",
        });
      }catch(error) {
console.log(error)
  Swal.fire({
          title: "Error",
          text: error.data,
          icon: "warning",
        });
      }
        
      
    }
  },
}
</script>

<style>

</style>