import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/UserProfile.vue";
import Claim from "../views/add_claim_form.vue";
import Asp_List from "../views/asp_list.vue";

import Invoice from "../views/Dashboard/PageVisitsTable.vue"
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import invoice_details_page from "../views/invoice_details_page.vue";
import Employee  from "../views/add_hod.vue"
import Emp_list from "../views/emp_list.vue"
import forget_password from "../views/forget_password.vue"
import Reset_Password from "../views/check_var.vue"
import firstPass from "../views/firstPassChng.vue"


const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
        beforeEnter: guardMyroute,
      },
      {
        path: "/create_user",
        name: "profile",
        components: { default: Profile },
        beforeEnter: guardMyroute,
      },
      {
        path: "/asp/edit/:asp_id",
        name: "profile_edit",
        components: { default: Profile },
        beforeEnter: guardMyroute,
      },
      {
        path: "/invoice_list",
        name: "Invoice",
        components: { default: Invoice },
        beforeEnter: guardMyroute,
      },
      {
        path: "/claim/edit/:invoice_id",
        name: "claim_edit",
        components: { default: Claim },
        beforeEnter: guardMyroute,
      },
      {
        path: "/claim",
        name: "claim",
        components: { default: Claim },
        beforeEnter: guardMyroute,
      },
      {
        path: "/employee",
        name: "Employee",
        components: { default: Employee },
        beforeEnter: guardMyroute,
      },
      {
        path: "/employee/edit/:emp_id",
        name: "Employee_edit",
        components: { default: Employee },
        beforeEnter: guardMyroute,
      },
      {
        path: "/invoice_details_page/:invoice_id",
        name: "invoice_details_page",
        components: { default: invoice_details_page },
        beforeEnter: guardMyroute,
      },
      {
        path: "/asp_list",
        name: "asp list",
        components: { default: Asp_List },
        beforeEnter: guardMyroute,
      },
      {
        path: "/emp_list",
        name: "emp list",
        components: { default: Emp_list },
        beforeEnter: guardMyroute,
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/first_password",
        name: "firstPass",
        components: { default: firstPass },
      },
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/forget_password",
        name: "forget_password",
        components: { default: forget_password },
      },
      {
        path: "/resetPassword",
        name: "reset_Password",
        components: { default: Reset_Password },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem("token")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}

// console.log(router);
// const token = { token: localStorage.getItem("token") };
// export {token};
// router.beforeEach((to) => {

//   console.log(to);
//   if (to.meta.requiresAuth) {
//     if (token.token) {
//       router.push("/dashboard");
//     } else {
//       router.push("/login");
//     }
//   }
// });
// router.beforeEach((to) => {
//   if (to.meta.requiresAuth) {
//     console.log(to.meta)
//     if (localStorage.getItem("token")) {
//       console.log("hii");
//       //router.push({ path: "/dashboard" });

//     } else {
//       //router.push({ path: "/login" });
//     }
//   }
// });

export default router;
