import { createApp } from "vue";
import App from "@/App.vue";
import Vuex from "vuex";

const appInstance = createApp(App);
appInstance.use(Vuex);
import userStorage from "./userStore";

export default new Vuex.Store({
  modules: {
    userStorage,
  },
  strict: false,
});
