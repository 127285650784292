<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>
    <div class="container">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h6 class="heading-small text-muted mb-4">User information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      required
                      label="Franchaise Name"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.franchise_name.$error"
                      >
                        &nbsp;{{ v$.model.franchise_name.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="Franchaise Name"
                        class="form-control form-control-alternative"
                        v-model="model.franchise_name"
                      />
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      required
                      label="User Name"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.user_name.$error"
                      >
                        &nbsp;{{ v$.model.user_name.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="User Name"
                        class="form-control form-control-alternative"
                        v-model="model.user_name"
                      />
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="required">Role</label>
                    <span
                      class="validation-msg"
                      v-if="v$.model.role.$error"
                    >
                      &nbsp;{{ v$.model.role.$errors[0].$message }}
                    </span><br />
                    <select class="select_input" v-model="model.role" :disabled="form_type == 'Edit'">
                      <option value="sa">SA</option>
                      <option value="ba">BA</option>
                      <option value="hybrid">HYBRID</option>
                      <option value="ssd">SSD</option>
                    </select>
                  </div>
                   <div class="col-lg-4">
                     <base-input
                      required
                      label="Phone"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.phone.$error"
                      >
                        &nbsp;{{ v$.model.phone.$errors[0].$message }}
                      </span>
                      <input
                      :disabled="form_type == 'Edit'"
                        type="text"
                        placeholder="Phone"
                        class="form-control form-control-alternative"
                        v-model="model.phone"
                      />
                    </base-input>
                  </div>
                  <div class="col-lg-4">
                     <base-input
                      required
                      label="Email"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.email.$error"
                      >
                        &nbsp;{{ v$.model.email.$errors[0].$message }}
                      </span>
                      <input
                      :disabled="form_type == 'Edit'"
                        type="email"
                        placeholder="Email"
                        class="form-control form-control-alternative"
                        v-model="model.email"
                      />
                    </base-input>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Contact information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Address 1"
                      input-classes="form-control-alternative"
                    >
                      <input
                        type="text"
                        placeholder="Address 1"
                        class="form-control form-control-alternative"
                        v-model="user_address.address1"
                    /></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Address 2"
                      input-classes="form-control-alternative"
                    >
                      <input
                        type="text"
                        placeholder="Address 2"
                        class="form-control form-control-alternative"
                        v-model="user_address.address2"
                    /></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                   
                        <label class="required">Zone</label>
                         <span
                        class="validation-msg"
                        v-if="v$.zone.$error"
                      >
                        &nbsp;{{ v$.zone.$errors[0].$message }}
                      </span><br />
                    <select @change="fetchRsm" class="select_input" v-model="zone">
    
                      <option v-for="zone in zone_list" :key="zone" :value="zone">{{zone.zone}}</option>
                    </select>
                  </div>
                    <div class="col-lg-3">
                  
                        <label class="required">RSM Name</label>
                          <span
                        class="validation-msg"
                        v-if="v$.employee_id.$error"
                      >
                        &nbsp;{{ v$.employee_id.$errors[0].$message }}
                      </span>
                      <br />
                    <select class="select_input" v-model="employee_id">
    
                      <option v-for="rsm in rsm_list" :key="rsm" :value="rsm.id">{{rsm.name}}</option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <base-input required
                      label="Pincode"
                      input-classes="form-control-alternative"
                    >
                    <span
                        class="validation-msg"
                        v-if="v$.user_address.pin_code.$error"
                      >
                        &nbsp;{{ v$.user_address.pin_code.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="Pincode"
                        class="form-control form-control-alternative"
                        v-model="user_address.pin_code"
                    /></base-input>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">Bank information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <base-input required
                      label="GST"
                      input-classes="form-control-alternative"
                    >
                    <span
                        class="validation-msg"
                        v-if="v$.bank.gst.$error"
                      >
                        &nbsp;{{ v$.bank.gst.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        placeholder="GST"
                        v-model="bank.gst"
                    /></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input required
                      label="Bank Name"
                      input-classes="form-control-alternative"
                    >
                     <span
                        class="validation-msg"
                        v-if="v$.bank.bank_name.$error"
                      >
                        &nbsp;{{ v$.bank.bank_name.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        placeholder="Bank Name"
                        v-model="bank.bank_name"
                    /></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input required
                      label="Branch Name"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.bank.branch_name.$error"
                      >
                        &nbsp;{{ v$.bank.branch_name.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        placeholder="Branch Name"
                        v-model="bank.branch_name"
                    /></base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input required
                      label="IFSC Code"
                      input-classes="form-control-alternative"
                    >
                       <span
                        class="validation-msg"
                        v-if="v$.bank.ifsc_code.$error"
                      >
                        &nbsp;{{ v$.bank.ifsc_code.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        placeholder="IFSC Code"
                        v-model="bank.ifsc_code"
                    /></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input required label="Account Number">
                     <span
                        class="validation-msg"
                        v-if="v$.bank.account_no.$error"
                      >
                        &nbsp;{{ v$.bank.account_no.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="Account Number"
                        class="form-control form-control-alternative"
                        v-model="bank.account_no"
                        :disabled="confirm_accountNumber != ''"
                      />
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input required label="Confirm Account Number">
                    <span
                        class="validation-msg"
                        v-if="v$.confirm_accountNumber.$error"
                      >
                        &nbsp;{{ v$.confirm_accountNumber.$errors[0].$message }}
                      </span>
                      <input
                        @input="confirm_accountNumber_check"
                        type="text"
                        placeholder="Account Number"
                        class="form-control form-control-alternative"
                        v-model="confirm_accountNumber"
                      />
                      <div v-if="error_text" class="error_txt">
                        Please enter Correct Account Number
                      </div>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row text-right">
                <div class="col-lg-6 text-right">
                  <base-button
                    @mouseup="addUser"
                    type="success"
                    v-if="form_type == 'create'"
                    >Submit</base-button
                  >
                  <base-button @mouseup="updateUser" type="success" v-else
                    >Update</base-button
                  >
                  <base-button
                    type="danger"
                    @mouseup="
                      $router.push({
                        name: 'asp list',
                      })
                    "
                    >Cancel</base-button
                  >
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required,minLength,maxLength } from "@vuelidate/validators";
import { AddAspApi, fetchSpecUser, Asp_edit, fetchZoneListApi, rsmFetch } from "../api/user";
export default {
  name: "user-profile",
  mounted() {
    this.fetchZoneList()
    if (this.$route.params.asp_id) {
      this.fetchUserDEtails(this.$route.params.asp_id);
      this.form_type = "Edit";
    }
  },
  validations() {
    return {
      confirm_accountNumber:{ required},
      employee_id:{required},
      zone:{ required },
      user_address:{
        pin_code:{required}
      },
      model: {
        franchise_name: { required },
        user_name: { required },
        role: { required },
        email: { required },
        phone:{required,minLength:minLength(1),maxLength:maxLength(10)}
      },
      bank: {
        bank_name: { required },
        branch_name: { required },
        account_no: { required },
        ifsc_code: { required },
        gst: { required },
      },
    };
  },
  data() {
    return {
      employee_id:'',
      zone:{},
      v$: useValidate(),
      user_id: "",
      form_type: "create",
      model: {
        franchise_name: "",
        user_name: "",
        role: "",
        email:'',
        phone:'',
      },
      zone_list:[],
      user_address: {
        address1: "",
        address2: "",
        pin_code: "",
      },
      bank: {
        bank_name: "",
        branch_name: "",
        account_no: "",
        ifsc_code: "",
        gst: "",
      },
      confirm_accountNumber: "",
      error_text: false,
      rsm_list:[]
    };
  },
  methods: {
    async fetchRsm(){
      const data = await rsmFetch(this.zone.id)
      this.rsm_list = data.data.data.employee
    },
          async fetchZoneList(){
          const data = await fetchZoneListApi()
          this.zone_list = data.data.data.zone
          console.log(data)
      },
    async updateUser() {
      try{const payload = {
        ...this.model,
        employee_id:this.employee_id,
        user_address: {...this.user_address,zone:this.zone.zone,zone_id:this.zone.id},
        user_bank: this.bank,
      };
      const data = await Asp_edit(this.user_id, payload);
      console.log(data);
      Swal.fire({
        title: "Success",
        text: "ASP updated successfully",
        icon: "success",
      });
      this.$router.push({ name: "asp list" });
      }catch(e){
         Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
      }
      
    },
    async fetchUserDEtails(id) {
      const response = await fetchSpecUser(id);
      const userDetails = response.data.data.user;
      console.log(response.data.data.user);
      this.user_id = id;
      this.model.franchise_name = userDetails.franchise_name;
      this.model.user_name = userDetails.user_name;
      this.model.role = userDetails.role;
      this.model.email = userDetails.email;
      this.user_address = userDetails.user_address;
      this.bank = userDetails.user_bank;
       this.zone= {id:userDetails.user_address.zone_id,zone:userDetails.user_address.zone}
      this.fetchRsm()
      this.employee_id = userDetails.employee_id;
      this.confirm_accountNumber = userDetails.user_bank.account_no;
      this.model.phone = userDetails.phone;
     
    },
    async addUser() {
      try {
        this.v$.$validate(); // checks all inputs
        if (!this.v$.$error) {
          // if ANY fail validation

          const payload = {
            ...this.model,
            employee_id:this.employee_id,
            user_address: {...this.user_address,zone:this.zone.zone,zone_id:this.zone.id},
            user_bank: this.bank,
          };
          const { data } = await AddAspApi(payload);
          console.log(data);
          Swal.fire({
            title: "Success",
            text: "ASP created successfully",
            icon: "success",
          });
          this.$router.push({ name: "asp list" });
        } else {
          Swal.fire({
            title: "Error",
            text: "Validation failed",
            icon: "warning",
          });
        }
      } catch (e) {
        Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
        console.log(e.response.data.message);
      }
    },
    confirm_accountNumber_check() {
      if (this.confirm_accountNumber !== this.bank.account_no) {
        this.error_text = true;
      } else {
        this.error_text = false;
      }
    },
  },
};
</script>
<style>
.error_txt {
  color: red;
  font-size: 12px;
}
.select_input {
  padding: .625rem .50rem;
  width: 100;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
}
.validation-msg {
  color: red;
}
</style>
