const userStorage = {
  state: {
    userInfo: localStorage.getItem("userInfo") || null,
  },
  mutations: {
    SET_USER_INFO_MUTATION: (state, payload) => {
      state.userInfo = JSON.stringify(payload);
    },
  },
  actions: {
    SET_USER_INFO: ({ commit }, payload) => {
      commit("SET_USER_INFO_MUTATION", payload);
    },
  },
  getters: {
    get_userinfo_data(state) {
      return JSON.parse(state.userInfo);
    }
  },
};

export default userStorage;
