<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>
    <div class="row">
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="ASP Type:BA"
          type="gradient-red"
          :sub-title="`${ba}`"
          icon="ni ni-active-40"
          class="mb-4 mb-xl-0"
        >
        </stats-card>
      </div>
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="ASP Type:SSD"
          type="gradient-red"
          :sub-title="`${ssd}`"
          icon="ni ni-active-40"
          class="mb-4 mb-xl-0"
        >
        </stats-card>
      </div>
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="ASP Type:SA"
          type="gradient-red"
          :sub-title="`${sa}`"
          icon="ni ni-active-40"
          class="mb-4 mb-xl-0"
        >
        </stats-card>
      </div>
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="ASP Type:HYBRID"
          type="gradient-red"
          :sub-title="`${hybrid}`"
          icon="ni ni-active-40"
          class="mb-4 mb-xl-0"
        >
        </stats-card>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchCount } from "../../api/user";
export default {
  mounted() {
    this.fetchCountFun();
    console.log("");
  },
  data() {
    return {
      ba: 0,
      ssd: 0,
      hybrid: 0,
      sa: 0,
    };
  },
  methods: {
    async fetchCountFun() {
      const data =await fetchCount();
      this.ba=data.data.data.ba_count
      this.ssd=data.data.data.ssd_count
      this.hybrid=data.data.data.hybrid_count
      this.sa=data.data.data.sa_count
      console.log(data.data);
    },
  },
};
</script>
