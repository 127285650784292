<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />
        <sidebar-item
        v-if="flag=='asp'"
          :link="{
            name: 'Add Claim',
            icon: 'fa fa-plus-circle text-orange',
            path: '/claim',
          }"
        />
        <sidebar-item
         v-if="user=='admin'"
          :link="{
            name: 'ASP List',
            icon: 'fa fa-list text-orange',
            path: '/asp_list',
          }"
        />
        <sidebar-item
         v-if="user=='admin'"
          :link="{
            name: 'Employee List',
            icon: 'fa fa-list text-orange',
            path: '/emp_list',
          }"
        />
         <sidebar-item
          v-if="user!='hod'"
          :link="{
            name: 'Invoice List',
            icon: 'fa fa-list text-orange',
            path: '/invoice_list',
          }"
        />
        <!-- <sidebar-item
        v-if="user=='admin'"
          :link="{
            name: 'Create User',
            icon: 'ni ni-single-02 text-yellow',
            path: '/create_user',
          }"
        /> -->
        <!-- <sidebar-item
          :link="{
            name: 'Login',
            icon: 'ni ni-key-25 text-info',
            path: '/login',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'ni ni-key-25 text-info',
            path: '/login',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      user: "",
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userInfo")).role;
    this.flag =localStorage.getItem("flag")   
    console.log(this.flag);
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss"></style>
