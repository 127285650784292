<template>
  <div class="container">
     <div class="row">
          <div class="col-lg-6">
              <base-input required label="User Name">
                    <input
                    disabled
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="user_name"
                    />
                  </base-input>
          </div>          
          <div class="col-lg-6">
              <base-input required label="Old Password">
                    <span class="validation-msg" v-if="v$.old_pass.$error">
                      &nbsp;{{ v$.old_pass.$errors[0].$message }}
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="old_pass"
                    />
                  </base-input>
          </div>   
      </div>
      <div class="row">
          <div class="col-lg-6">
              <base-input required label="New Password">
                    <span class="validation-msg" v-if="v$.new_pass.$error">
                      &nbsp;{{ v$.new_pass.$errors[0].$message }}
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="new_pass"
                      :disabled="confirm_password != ''"
                    />
                  </base-input>
          </div>
          <div class="col-lg-6">
<base-input label="Confirm Password">
                      <input
                        @input="confirm_password_check"
                        type="text"
                        placeholder="Password"
                        class="form-control form-control-alternative"
                        v-model="confirm_password"
                      />
                      <div v-if="error_text" class="error_txt">
                        Please enter Correct Password
                      </div>
                    </base-input>
          </div>
          
      </div>
      <div class="col-lg-3 mt-4">
              <base-button
                    @mouseup="resetPassword"
                    class="btn btn-success"
                    type="primary"
                    >Reset Password</base-button>
                  
          </div>
        
  </div>
</template>

<script>
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ResetPassword} from "../api/login"
export default {
 validations() {
     return {
        old_pass: { required },
         new_pass: { required },
         confirm_password:{required}
     }
 },
  data() {
    return {
      user_name:'',
        error_text:false,
      v$: useValidate(),
      old_pass:'',
      new_pass:'',
      confirm_password:''
    }
  },

  mounted(){
    this.user_name =JSON.parse(localStorage.getItem("userInfo")).user_name
  },
  methods: {
    confirm_password_check() {
      if (this.confirm_password !== this.new_pass) {
        this.error_text = true;
      } else {
        this.error_text = false;
      }
    },
    async resetPassword (){
      try{
        const payload = {
            old_password:this.old_pass,
            password:this.new_pass,
        }
        const data = await ResetPassword(payload)
        console.log(data)
          Swal.fire({
          title: "Success",
          text: "Password updated successfully",
          icon: "success",
        });
        this.$router.push("/dashboard").catch(() => {});
      } catch (e) {
  Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
      }
    }
  },
}
</script>

<style>
.error_txt {
  color: red;
  font-size: 12px;
}
</style>