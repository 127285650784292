<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>
    <div class="card mt-4">
      <div class="container">
         <div class="col-lg-3 m-2">
            <base-button type="success" v-if="asp_type=='admin'" @click="$router.push('/employee')"
              ><i class="fa fa-plus"></i> Add Employee</base-button
            >
          </div>
        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="asp_list">
            <template v-slot:columns>
              <th>Name</th>
              <th>User Name</th>
              <th>Role</th>
              <th>Email</th>
              <th>Zone</th>
              <th>ASP Name</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <td scope="row">
                 {{
                  row.item.name != null
                    ? row.item.name
                    : ""
                }}
              </td>
             
              <td>
                {{
                  row.item.user_name != null
                    ? row.item.user_name
                    : ""
                }}
              </td>
              <td>
                {{
                  row.item.role != null
                    ? getRole(row.item.role)
                    : ""
                }}
              </td>
               <td>
                {{
                  row.item.email != null
                    ? row.item.email
                    : ""
                }}
              </td>
                <td>
                {{getZone(row.item.zones)}}
              </td>
               <td>{{getUserlist(row.item.users)}}</td>
              <td v-if="asp_type != 'service_hod'">
                <base-button
                  @click="editemp(row.item.id)"
                  icon="fas fa-pen"
                  size="sm"
                  type="success"
                ></base-button>

                <Toggle v-if="asp_type != 'service_hod'"
                  v-model="row.item.is_activated"
                  @mouseup="defToggle(row.item.id)"
                />
              </td>
            </template>
          </base-table>
          <pagination :total_Count="pagination.total" :current_page="pagination.default" :onpageChng="onpageChng" :rows_per_page="pagination.rows_per_page"></pagination>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="deactivateMOdal">
    <template v-slot:header>
      <h5 class="modal-title" id="exampleModalLabel">ASP Deactivated Remark</h5>
    </template>
    <div>
      <label>Remark</label>
      <input
        type="text"
        placeholder="Remark"
        class="form-control form-control-alternative"
        v-model="remark"
                  />
    </div>
    <template v-slot:footer>
      <base-button type="secondary" @mouseup="closeModal"
        >Close</base-button
      >
      <base-button @mouseup="deactivateAsp" type="primary">Save changes</base-button>
    </template>
  </modal>
</template>
<script>
import pagination from "./Dashboard/pagination.vue"
import Toggle from "@vueform/toggle";
import Swal from "sweetalert2";
import { emp_List_Api, Emp_Deactivate, Emp_Reactivate } from "../api/user";
export default {
  components: {
    Toggle,
    pagination
  },
  name: "page-visits-table",
  data() {
    return {
      userlist:'',
      asp_type:JSON.parse(localStorage.getItem("userInfo")).role,
       pagination: {
        total: 0,
        default: 0,
        rows_per_page: 50,
        pageCount: 0,
      },
      asp_list: [],
      search: {
        franchise_name: "",
        role: "",
        zone: "",
      },
      deactivateMOdal: false,
      deactivate_id:null,
      remark:''
    };
  },
  created() {
    this.fetchAspList();
  },
  mounted() {
    this.asp_type = JSON.parse(localStorage.getItem("userInfo")).role
  },
    watch: {
    'pagination.default':function(){
    this.fetchAspList();
    }
  },
  methods: {
    getZone(zone){
if(zone.length > 0){
zone = zone.map(function(elem){
        return elem.zone;
    }).join(", ")
    return zone
      }else{
        return "";
      }
    },
    getRole(role){
    if(role=='hod'){
      return 'Regional Head'
    } else if(role=='service_hod'){
      return 'Service Head'
    } else if(role=='finance') {
      return 'Finance'
    }
    },
    getUserlist(users){
      if(users.length > 0){
users = users.map(function(elem){
        return elem.franchise_name;
    }).join(", ")
    return users
      }else{
        return "";
      }
 
    },
    onpageChng (page) {
      this.pagination.default = page;
    },
    async deactivateAsp(){
      try{
         const payload = {
        remarks:this.remark,
      }
      const data = await Emp_Deactivate(this.deactivate_id,payload);
      console.log(data)
      this.deactivateMOdal = false
       Swal.fire({
        title: "Success",
        text: "ASP Deactivated successfully",
        icon: "success",
      });
      this.remark = ""
      } catch (e) {
         Swal.fire({
        title: "warning",
        text: "Something went wrong",
        icon: "warning",
      });
      }

    },
    async closeModal(){
      this.deactivateMOdal = false
     
      let i = this.asp_list.findIndex(ele=> ele.id==this.deactivate_id)
      this.asp_list[i].is_activated = true
       console.log( this.asp_list[i].is_activated)
    }
    ,
    async defToggle(index) {
      this.deactivate_id = index;
      console.log(this.deactivate_id,index)
      let i = this.asp_list.findIndex(ele=> ele.id==index)
      if(this.asp_list[i].is_activated == true) {
        this.deactivateMOdal = true;
      } else {
        const data = await Emp_Reactivate(this.deactivate_id)
 Swal.fire({
        title: "Success",
        text: "ASP Activated successfully",
        icon: "success",
      });
      console.log(data)
      }
      
    },
    resetFilter() {
      this.search.franchise_name = "";
      (this.search.zone = ""), (this.search.role = ""), this.fetchAspList();
    },
    // pagechng(e){
    //   this.pagination.default = parseInt(e.target.innerText);
    //   console.log(e)
    // },
    async editemp(value) {

      this.$router.push({
        name: "Employee_edit",
        params: { emp_id:value },
      });
    },
    async searchByFilter() {
      this.fetchAspList();
    },
    async fetchAspList() {
      const payload = {};
     const offset = this.pagination.default * this.pagination.rows_per_page;
      const limit = this.pagination.rows_per_page;
      const data = await emp_List_Api(payload,offset,limit);
      this.asp_list = data.data.data.employees;
   
      this.pagination.total = data.data.data.count;
      // this.pagination.pageCount = Math.ceil( parseInt( data.data.data.count) / this.pagination.rows_per_page)
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
