<template>
  <div class="main-content">
     
    <!-- Header -->
    <div class="py-lg-5">
      
      <div class="container">
        <div class="header-body text-center">
          
          <div class="row justify-content-center">
           <div class="col-lg-5 col-md-6 md">
            <figure><img src="img/brand/Kutchina__Logo__.jpg"/> <figcaption>Claim Process System for Authorized Service Partner</figcaption></figure>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--5 pb-5">
      <router-view></router-view>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <!-- <a
                href="https://www.creative-tim.com"
                class="font-weight-bold ml-1"
                target="_blank"
                >Creative Tim</a
              > -->
            </div>
          </div>
          <div class="col-xl-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-xl-end"
            >
              <!-- <li class="nav-item">
                <a
                  href="https://www.creative-tim.com"
                  class="nav-link"
                  target="_blank"
                  >Creative Tim</a
                >
              </li> -->
              <!-- <li class="nav-item">
                <a
                  href="https://www.creative-tim.com/presentation"
                  class="nav-link"
                  target="_blank"
                  >About Us</a
                >
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};
</script>
<style>
.bg_color {
  background-color: rgb(0, 96, 170) !important;
}
</style>
<style scoped>
img {
    vertical-align: middle;
    border-style: none;
    width: 60%;
    height: 65px;
    /* margin-bottom: 50px; */
}
</style>
