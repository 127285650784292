<template>
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <base-input required label="User Name">
                    <span class="validation-msg" v-if="v$.email.$error">
                      &nbsp;{{ v$.email.$errors[0].$message }}
                    </span>
                    <input
                    disabled
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="email"
                    />
                  </base-input>
          </div>
          <div class="col-lg-6">
<base-input required label="OTP">
                    <span class="validation-msg" v-if="v$.otp.$error">
                      &nbsp;{{ v$.otp.$errors[0].$message }}
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="otp"
                    />
                  </base-input>
          </div>
          
      </div>
      <div class="row">
          <div class="col-lg-6">
              <base-input required label="Password">
                    <span class="validation-msg" v-if="v$.password.$error">
                      &nbsp;{{ v$.password.$errors[0].$message }}
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="password"
                      :disabled="confirm_password != ''"
                    />
                  </base-input>
          </div>
          <div class="col-lg-6">
<base-input label="Confirm Password">
                      <input
                        @input="confirm_password_check"
                        type="text"
                        placeholder="Password"
                        class="form-control form-control-alternative"
                        v-model="confirm_password"
                      />
                      <div v-if="error_text" class="error_txt">
                        Please enter Correct Password
                      </div>
                    </base-input>
          </div>
          
      </div>
      <div class="col-lg-3 mt-4">
              <base-button
                    @mouseup="resetPassword"
                    class="btn btn-success"
                    type="primary"
                    >Reset Password</base-button>
                  
          </div>
        
  </div>
</template>

<script>
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { validatePassword} from "../api/login"
export default {
  name: "reset_Password",
 validations() {
     return {
        email: { required },
         otp: { required },
         password:{required}
     }
 },
  data() {
    return {
      
        confirm_password:'',
        error_text:false,
      v$: useValidate(),
      email:'',
      otp:'',
      password:''
    }
  },
  mounted() {
    this.email = this.$route.params.user_name
    
  },
  methods: {
    confirm_password_check() {
      if (this.confirm_password !== this.password) {
        this.error_text = true;
      } else {
        this.error_text = false;
      }
    },
    async resetPassword (){
      try{
         const payload = {
            user_name:this.email,
            v_code:this.otp,
            password:this.password,
        }
        const data = await validatePassword(payload)
        console.log(data)
          Swal.fire({
          title: "Success",
          text: "Password Reset successfully",
          icon: "success",
        });
         this.$router.push({
        name: "login"});

      }catch(e){
        console.log(e);
        Swal.fire({
          title: "Error",
          text: e,
          icon: "warning",
        });
      }
       
    }
  },
}
</script>

<style>
.error_txt {
  color: red;
  font-size: 12px;
}
</style>