import request from "../axios";

export function AddAspApi(query) {
  //
  return request({
    url: "user/register",
    method: "POST",
    data: query,
  });
}

export function Asp_List_Api(payload,offset,limit) {
  return request({
    url: `user/list?offset=${offset}&limit=${limit}`,
    method: "POST",
    data:payload,
  });
}
export function Asp_edit(id, payload) {
  return request({
    url: `user/update/${id}`,
    method: "POST",
    data: payload,
  });
}

export function asp_delete(id) {
  return request({
    url: `user/delete/${id}`,
    method: "GET"
  });
}

export function fetchSpecUser(id) {
  return request({
    url: `user/details/${id}`,
    method: "GET"
  });
}

export function fetchCount() {
  return request({
    url: "user/count",
    method: "GET"
  });
}

export function ASP_Deactivate(id, payload) {
  return request({
    url: `user/deactivate/${id}`,
    method: "POST",
    data: payload,
  });
}
export function ASP_Reactivate(id) {
  return request({
    url: `user/reactivate/${id}`,
    method: "GET",
  });
}

export function fetchZoneListApi() {
  return request({
    url: "zone/list",
    method: "GET"
  });
}

export function AddEmpApi(payload) {
  return request({
    url: "employee/register",
    method: "POST",
    data: payload
  });
}

export function UpdateEmpApi(payload,id) {
  return request({
    url: `employee/update/${id}`,
    method: "POST",
    data: payload
  });
}

export function emp_List_Api(payload,offset,limit) {
  return request({
    url: `employee/list?offset=${offset}&limit=${limit}`,
    method: "POST",
    data:payload
  });
}

export function fetchSpecEmp(id) {
  return request({
    url: `employee/details/${id}`,
    method: "GET"
  });
}

export function Emp_Deactivate(id, payload) {
  return request({
    url: `employee/deactivate/${id}`,
    method: "POST",
    data: payload,
  });
}
export function Emp_Reactivate(id) {
  return request({
    url: `employee/reactivate/${id}`,
    method: "GET",
  });
}

export function rsmFetch(zone_id) {
  return request({
    url: `employee/get_rem_name/${zone_id}`,
    method: "GET",
  });
}