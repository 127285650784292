<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <div class="row">
                <div class="col-lg-3">
                  <base-input required label="Invoice Number">
                    <span class="validation-msg" v-if="v$.invoice_no.$error">
                      &nbsp;{{ v$.invoice_no.$errors[0].$message }}
                    </span>
                    <input
                      :disabled="this.$route.params.id"
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="invoice_no"
                    />
                  </base-input>
                </div>
                <div class="col-lg-3">
                  <label class="form-control-lebel required">Month</label>
                  <span class="validation-msg" v-if="v$.month.$error">
                    &nbsp;{{ v$.month.$errors[0].$message }}
                  </span>
                  <select
                    class="select_input_month form-control"
                    v-model="month"
                    @change="addmonth"
                  >
                    <option :value="i.id" v-for="i in month_array" :key="i">
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3">
                  <label class="form-control-lebel required">Year</label>
                  <span class="validation-msg" v-if="v$.year.$error">
                    &nbsp;{{ v$.year.$errors[0].$message }}
                  </span>

                  <select
                    class="select_input_month form-control"
                    v-model="year"
                    @change="addYear"
                  >
                    <option
                      :value="year"
                      v-for="year in year_array"
                      :key="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3">
                  <base-input required label="RSM Name"><br>
                       <input
                      disabled
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="rem_name"
                    />
                  </base-input>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-2">
                  <span class="required">Expenses Name</span>
                </div>
                <div class="col-lg-1">
                  <span class="required">Month</span>
                </div>
                <div class="col-lg-1 px-1">
                  <span class="required">Year</span>
                </div>
               <div class="col-lg-2"><span>Fixed</span></div>
                <div class="col-lg-2">
                  <span>Variable</span>
                </div>
              </div>
              <div
                class="row mt-2"
                v-for="(item, index) of expenses_list"
                :key="index"
              >
                <div class="col-lg-2">
                  <select
                    class="select_input form-control"
                    @change="fixedPay(index)"
                    v-model="item.expense_desc"
                  >
                    <option value="remunaration">Remunaration</option>
                    <option value="AMC Commision">AMC Commision</option>
                    <option value="Approved Travel Expenses">
                      Approved Travel Expenses
                    </option>
                    <option value="Approved Spare Purchase">
                      Approved Spare Purchase
                    </option>
                    <option value="Outside Repaired Claim">
                      Outside Repaired Claim
                    </option>
                    <option value="Approved Other Expenses">
                      Approved Other Expenses
                    </option>
                    <option value="Royality Deduction">
                      Royality Deduction
                    </option>
                  </select>
                </div>
                <div class="col-lg-1">
                  <!-- <input
                    type="text"
                    placeholder="Month"
                    class="form-control form-control-alternative"
                    v-model="item.month"
                    
                  /> -->
                   <select
                    class="select_input_month form-control"
                    v-model="item.month"
                    :disabled="true"
                    style="padding:0.25rem"
                  >
                    <option :value="i.id" v-for="i in month_array" :key="i">
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-1 px-1">
                 <input
                    type="text"
                    style="padding:0.25rem"
                    placeholder="Year"
                    class="form-control form-control-alternative"
                    v-model="item.year"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2 px-2">
                  <!-- <input
                    v-if="item.expense_desc == 'remunaration'"
                    @blur="preview"
                    style="padding:0.25rem"
                    type="number"
                    placeholder="Fixed"
                    class="form-control form-control-alternative"
                    v-model="item.fixed"
                    :disabled="true"  
                  /> -->
                  <!--  :disabled="asp_type == 'hybrid' ? false : true"  // due to client requirements 25/02/2022 -->
                  <input
                  style="padding: 0px 45px 0px 42px;"
                   v-if="item.expense_desc == 'remunaration'"
                   @blur="preview"
                    type="number"
                    placeholder="Fixed"
                    class="form-control form-control-alternative"
                    v-model="item.fixed"
                  /> 
                   <input
                   v-else
                    type="number"
                    placeholder="N/A"
                    class="form-control form-control-alternative"
                    :disabled="true"
                  />
                </div>
                <!-- :disabled="true" 12052022 -->
                <div class="col-lg-2">
                  <input
                  style="padding: 0px 40px;"
                    @blur="preview"
                    type="number"
                    placeholder="Variable"
                    class="form-control form-control-alternative"
                    v-model="item.variable"
                  />
                </div>
                <div
                  class="col-lg-2"
                  v-show="
                    item.expense_desc == 'Approved Travel Expenses' ||
                    item.expense_desc == 'Approved Spare Purchase' ||
                    item.expense_desc == 'Outside Repaired Claim' ||
                    item.expense_desc == 'Approved Other Expenses'"
                >
                  
                
                  <input
                    ref="file"
                    type="file"
                    placeholder="File"
                    @change="uploadToAws($event.target.files)"
                    class="form-control form-control-alternative"
                  />
                </div>
                <div
                  class="col-lg-1"
                  v-if="
                    (item.expense_desc == 'Approved Travel Expenses' ||
                    item.expense_desc == 'Approved Spare Purchase' ||
                    item.expense_desc == 'Outside Repaired Claim' ||
                    item.expense_desc == 'Approved Other Expenses') && datafile!=''
                  "
                >
                  <base-button
                    type="submit"
                    @mouseup="uploadToDb(index)"
                    class="form-control form-control-alternative"
                    icon="fas fa-arrow-up"
                  >
                  </base-button>
                </div>
                <div class="col-lg-1">
                  <div class="input-group-append">
                    <base-button
                      size="sm"
                      type="primary"
                      @mouseup="addField"
                      icon="ni ni-fat-add"
                      :disabled="expenses_list.length > index + 1"
                    ></base-button>
                    <base-button
                      type="primary"
                      size="sm"
                      :disabled="expenses_list.length == 1"
                      @mouseup="remove(index)"
                      icon="ni ni-fat-delete"
                    ></base-button>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-2">
                  <label class="gst">GST@18%</label>
                </div>
                <div class="col-lg-2">
                  <input @change="cal_gst" type="checkbox" value="true" v-model="checked">
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-2">
                  <input
                    type="number"
                    placeholder="Total Variable"
                    class="form-control form-control-alternative"
                    v-model="total_gst"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-4">
                  <label>Amount without 18% gst</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-2">
                  <input
                    type="number"
                    placeholder="Total Variable"
                    class="form-control form-control-alternative"
                    v-model="total_amt"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2"></div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-2">
                  <label>Amount Payable</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-1"></div>
                <div class="col-lg-2">
                  <input
                    type="number"
                    placeholder="Total Amount"
                    class="form-control form-control-alternative"
                    v-model="total_amt_payable"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2">
                
                  <input
                    ref="file"
                    type="file"
                    placeholder="File"
                    @change="uploadToAwsInvoice($event.target.files)"
                    class="form-control form-control-alternative"
                  />
                </div>
                <div class="col-lg-1">
                  <base-button v-if="datafileInvoice!=''"
                  required
                    type="submit"
                    @mouseup="uploadToDbInvoice()"
                    class="form-control form-control-alternative"
                    icon="fas fa-arrow-up"
                  >
                  </base-button>
                  
                </div> 
              </div>
              <div class="row mt-2 mb-4">
                <div class="col-lg-12 text-center mt-4">
                  <!-- <base-button
                    type="info"
                    @mouseup="preview"
                    class="btn btn-secondary"
                    >Preview GST
                  </base-button> -->
                  <base-button
                    v-if="$route.params.invoice_id"
                    @mouseup="UpdateInvoice"
                    class="btn btn-success"
                    type="primary"
                    >Update</base-button
                  >
                  <base-button
                    v-else
                    @mouseup="invoiceCreate"
                    class="btn btn-success"
                    type="primary"
                    >Submit</base-button
                  >
                  <base-button
                    @mouseup="
                      $router.push({
                        name: 'dashboard',
                      })
                    "
                    type="danger"
                    >Cancel</base-button
                  >
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Datepicker from "vue3-datepicker";
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  CreateInvoice,
  UploadToAwsApi,
  fetchFileID,
  invoiceFetch,
  invoiceUpdate,
} from "../api/invoice";


export default {
  name: "claim",
  mounted() {
    this.rem_name = JSON.parse(localStorage.getItem("userInfo")).employee.name;
    this.employee_id =JSON.parse(localStorage.getItem("userInfo")).employee_id

    if (this.$route.params.invoice_id) {
      this.fetchINvoiceDetails(this.$route.params.invoice_id);
    }
  },
  validations() {
    return {
      invoice_no: { required },
      month: { required },
      year: { required },
      rem_name: { required },
      expenses_list: [
        {
          expense_desc: { required },
          year: { required },
        },
      ],
    };
  },
  data() {
    return {
      checked: false,
      v$: useValidate(),
      month_array:[{id:1,name:'Jan'},{id:2,name:'Feb'},{id:3,name:'Mar'},{id:4,name:'Apr'},{id:5,name:'May'},{id:6,name:'June'},{id:7,name:'July'},{id:8,name:'Aug'},{id:9,name:'Sept'},{id:10,name:'Oct'},{id:11,name:'Nov'},{id:12,name:'Dec'}],
      year_array: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      total_fixed: 0,
      date: "",
      invoice_no: "",
      month: "",
      year: "",
      rem_name: "",
      add_expenses_form: true,
      expenses_list: [
        {
          expense_desc: "",
          month: "",
          year: this.year,
          fixed: 0,
          variable: 0,
          invoice_line_item_files: [],
        },
      ],
      datafile: "",
      datafileInvoice:"",
      invoice_id: "",
      file_id: "",
      confirm_accountNumber: "",
      error_text: false,
      asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
      gst_row: false,
      total_amt: 0,
      total_gst: 0,
      total_amt_payable: 0,
      file_id_amount: null,
    };
  },
  methods: {
    async cal_gst(){
      console.log(this.checked)
      if(this.checked){
        this.gst_cal()
      } else {
        this.total_amt_calculations()
      }
    },
    async addYear(){
       this.expenses_list.forEach((element) => {
        element.year = this.year;
      });
    },
    async addmonth(){
       this.expenses_list.forEach((element) => {
         console.log(element.month,this.month)
        element.month = this.month;
      });
    
    },
    async preview() {
      console.log(this.checked,this.total_gst)
      // this.gst_row = !this.gst_row;
      this.checked?this.gst_cal():this.total_amt_calculations();
    },
    async total_amt_calculations() {
        this.total_gst = 0;
      this.total_amt = 0;
      this.expenses_list.forEach((element) => {
        console.log(element)
        this.total_amt = element.fixed + this.total_amt;
        if(element.expense_desc == 'Royality Deduction'){
           this.total_amt = this.total_amt- element.variable;
        } else {
           this.total_amt = element.variable + this.total_amt;
        }
        
       
      });
      this.total_amt_payable = this.total_amt;
      


    },
    async gst_cal() {
      this.total_amt = 0;
      this.total_gst = 0;
      this.total_amt_payable = 0;
      this.expenses_list.forEach((element) => {
        this.total_amt = element.fixed + this.total_amt;
         if(element.expense_desc == 'Royality Deduction'){
           this.total_amt = this.total_amt- element.variable;
        } else {
           this.total_amt = element.variable + this.total_amt;
        }
      });
      this.total_gst = Math.ceil((this.total_amt * 18) / 100);
      this.total_amt_payable = this.total_gst + this.total_amt;
    },
    async UpdateInvoice() {
      try {
        let error = false
        this.expenses_list.forEach(ele=> {
          if(ele.expense_desc=='Approved Travel Expenses' || ele.expense_desc=='Approved Spare Purchase' || ele.expense_desc=='Outside Repaired Claim' || ele.expense_desc=='Approved Other Expenses'){
            if(ele.invoice_line_item_files.length==0) {
              error = true
              Swal.fire({
            title: "Error",
            text: "Please Upload your file",
            icon: "warning",
          }); 
            }
          }
        })
        if(error==false) {
 const payload = {
          invoice_no: this.invoice_no,
          month: this.month,
          year: this.year,
          asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
          invoice_line_items: this.expenses_list,
          file_id: this.file_id_amount,
            amount_payable: this.total_amt_payable,
            gst: this.total_gst,
        };
        const data = await invoiceUpdate(payload, this.invoice_id);
        console.log(data);
        this.$router.push({ name: "dashboard" });
        Swal.fire({
          title: "Success",
          text: "Invoice updated successfully",
          icon: "success",
        });
        }
       
      } catch (e) {
        Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
        console.log(e);
      }
    },
    async fetchINvoiceDetails(id) {
      const data = await invoiceFetch(id);
      const invoiceDet = data.data.data.invoice;
      this.invoice_id = id;
      this.invoice_no = invoiceDet.invoice_no;
      this.month = invoiceDet.month;
      this.year = invoiceDet.year;
      //this.rem_name = invoiceDet.employee_id
      this.expenses_list = invoiceDet.invoice_line_items;
      this.total_amt_payable = invoiceDet.amount_payable;
      this.total_gst = invoiceDet.gst;
      this.file_id_amount = invoiceDet.file_id;
      this.total_amt = invoiceDet.amount_payable -this.total_gst
      this.checked=this.total_gst>0?true:false;
    },
    async uploadToDb(index) {
      const fetchPayload = {
        file_name: this.datafile.file_name,
        file_path: this.datafile.url,
      };
      const data = await fetchFileID(fetchPayload);
      console.log(data.data.data.file);
      this.file_id = data.data.data.file;
      console.log(this.file_id.id);
      Swal.fire({
        title: "Success",
        text: "File updated successfully",
        icon: "success",
      });
      // this.file_id = this.file_id.filter((element) => {
      //   return element.id;
      // });

      const uploadFile_id = { file_id: this.file_id.id };
      console.log(uploadFile_id, index);
      this.expenses_list[index].invoice_line_item_files=[uploadFile_id];
      this.datafile = ''
    },
    async uploadToDbInvoice() {
      const fetchPayload = {
        file_name: this.datafileInvoice.file_name,
        file_path: this.datafileInvoice.url,
      };
      const data = await fetchFileID(fetchPayload);
      console.log(data.data.data.file);
      this.file_id_amount = data.data.data.file.id;
      console.log(this.file_id_amount);
      Swal.fire({
        title: "Success",
        text: "File updated successfully",
        icon: "success",
      });
      this.datafileInvoice =''
    },
    async uploadToAws(files) {
      console.log(files[0])
      const file = files[0];
      let formData = new FormData();
      formData.append("file", file);
      const payload = formData;
      console.log(payload)
      const { data } = await UploadToAwsApi(payload);
      this.datafile = data.data;
    },
    async uploadToAwsInvoice(files){
      console.log(files[0])
      const file = files[0];
      let formData = new FormData();
      formData.append("file", file);
      const payload = formData;
      console.log(payload)
      const { data } = await UploadToAwsApi(payload);
      this.datafileInvoice = data.data;
    },
    remove(index) {
      this.expenses_list.splice(index, 1);
      this.checked?this.gst_cal():this.total_amt_calculations();
    },
    fixedPay(index) {
      // if (this.expenses_list[index].expense_desc == "remunaration") {
      //   if (this.asp_type == "sa") {
      //     this.expenses_list[index].fixed = 5000;
      //   } else if (this.asp_type == "ba") {
      //     this.expenses_list[index].fixed = 1000;
      //   } else if (this.asp_type == "hybrid") {
      //     this.expenses_list[index].fixed = 1000;
      //   } else if (this.asp_type == "ssd") {
      //     this.expenses_list[index].fixed = 1000;
      //   } 
      //   this.expenses_list[index].variable = 0
      //   this.checked?this.gst_cal():this.total_amt_calculations();
      // } else {
          this.expenses_list[index].fixed = 0
          this.expenses_list[index].variable = 0
    //    }
    },
    FindtotalVariable(index) {
      this.total_variable =
        this.total_variable + this.expenses_list[index].variable;
    },
    addField() {
      const payload = {
        expense_desc: "",
        month: this.month,
        year: this.year,
        fixed: 0,
        variable: 0,
        invoice_line_item_files: [],
      };
      this.expenses_list.push(payload);
    },
    async invoiceCreate() {
      try {
        let error = false
        this.v$.$validate(); // checks all inputs
       if(!this.v$.$error) {
          this.checked?this.gst_cal():this.total_amt_calculations();
           this.expenses_list.forEach(ele=> {
          if(ele.expense_desc=='Approved Travel Expenses' || ele.expense_desc=='Approved Spare Purchase' || ele.expense_desc=='Outside Repaired Claim' || ele.expense_desc=='Approved Other Expenses'){
            if(ele.invoice_line_item_files.length==0) {
              error = true
              Swal.fire({
            title: "Error",
            text: "Please Upload your file",
            icon: "warning",
          }); 
            } else {
              error = false
            }
          }
        })
        if(!error){
 const payload = {
            invoice_no: this.invoice_no,
            month: this.month,
            year: this.year,
            asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
          employee_id: this.employee_id,
          rem_name:this.rem_name,
            invoice_line_items: this.expenses_list,
            amount_payable: this.total_amt_payable,
            gst: this.total_gst,
            file_id: this.file_id_amount,
          };
          console.log(payload);
          const data = await CreateInvoice(payload);
          console.log(data);
          Swal.fire({
            title: "Success",
            text: "Invoice created successfully",
            icon: "success",
          });
          this.$router.push({ name: "dashboard" });
        }
         
        } else {
          Swal.fire({
            title: "Error",
            text: "Please enter all the required field",
            icon: "warning",
          });
        }
      } catch (e) {

        Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
        console.log(e);
      }
    },
  },
};
</script>
<style>
.error_txt {
  color: red;
  font-size: 12px;
}
.gst {
  color: blue;
  padding: 2px;
}
.select_input_month {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
  padding: .625rem .50rem;
  width: 100;
}
.required:after {
  content: " *";
}
</style>
