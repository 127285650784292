<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>
    <div class="card mt-4">
      <div class="container">
        <div class="align-items-center">
          <h4>Filter</h4>
          <div class="row">
            <div class="col-lg-2">
              <label>Invoice Number</label>
              <input
                type="text"
                class="form-control form-control-alternative"
                v-model="search.invoice_no"
              />
            </div>
            <div class="col-lg-2">
              <label>ASP Name</label>
              <input
                type="text"
                class="form-control form-control-alternative"
                v-model="search.franchise_name"
              />
            </div>
            <div class="col-lg-2">
              <label>Year</label><br />
              <select class="select_input" v-model="search.year">
                <option :value="year" v-for="year in year_array" :key="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="col-lg-2">
              <base-input>
                <label>Month</label><br />
                <select class="select_input" v-model="search.month">
                  <option :value="i.id" v-for="i in month_array" :key="i">
                    {{ i.name }}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="col-lg-2">
              <base-input>
                <label>Status</label><br />
                <select class="select_input" v-model="search.last_status">
                  <option :value="i.id" v-for="i in status_array" :key="i">
                    {{ i.status_name }}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="col-lg-2 mt-4">
              <base-button type="success" @mouseup="searchByFilter"
                ><i class="fa fa-search"></i
              ></base-button>
              <button
                @click="resetFilter"
                type="button"
                class="btn btn-secondary"
              >
                <i class="fa fa-undo"></i>
              </button>
            </div>
          </div>
        </div>
        <button style="color: green" @click="BulkdownloadInvoice()">
          <i class="fas fa-download"></i>
        </button>
        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="invoice_list">
            <template v-slot:columns>
              <th>Invoice Number</th>
              <th>ASP Name</th>
              <th>Created At</th>
               <th>Status</th>
              <th>Remark</th>
              <th>ACtion</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <td>
                {{ row.item.invoice_no }}
              </td>
              <td>
                {{
                  row.item.asp_name == null
                    ? row.item.user.franchise_name
                    : row.item.asp_name
                }}
              </td>
              <td v-if="row.item.created_at">
                {{ row.item.created_at }}
              </td>
               <td>
                {{ row.item.status }}
              </td>
              <td>
                {{row.item.last_remarks }}
              </td>
             
              <td>
                <base-button
                  @click="invoice_details_page(row.item.id)"
                  icon="fas fa-eye"
                  size="sm"
                  type="primary"
                ></base-button>
                <base-button
                  v-if="
                    row.item.last_status == 2 &&
                    flag== 'asp'
                  "
                  @click="editInvoice(row.item.id)"
                  icon="fas fa-pen"
                  size="sm"
                  type="primary"
                ></base-button>
                <base-button
                  v-if="user == 'finance' && row.item.last_status!=6"
                  @mouseup="statusChng(row.item.id,row.item.last_status)"
                  icon="fas fa-check-circle"
                  size="sm"
                  type="success"
                ></base-button>
                <button
                  style="color: green"
                  v-if="row.item.last_status != 2"
                  @click="downloadInvoice(row.item.id)"
                >
                  <i class="fas fa-download"></i>
                </button>
              </td>
            </template>
          </base-table>
          <pagination :total_Count="pagination.total" :current_page="pagination.default" :onpageChng="onpageChng" :rows_per_page="pagination.rows_per_page"></pagination>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="statusModal">
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Change Status</h3>
    </template>
    <label class="form-control-label">Status</label><br />
    <select
      class="select_input form-control"
      @change="addStatus"
      v-model="status_chng"
    >
      <option v-if="last_status_id < 4" value="4">Accept</option>
      <option v-if="last_status_id < 4" value="2">Reject</option>
      <option v-if="last_status_id >=4 && last_status_id <6" value="5">Partial Complete</option>
      <option v-if="last_status_id >= 4" value="6">Completed</option>
    </select>
    <base-input
      v-if="status_chng != 4"
      label="Remark"
      input-classes="form-control-alternative"
    >
      <input
        v-if="status_chng != 4"
        type="text"
        placeholder="Remark"
        class="form-control form-control-alternative"
        v-model="remark"
    /></base-input>
    <template v-slot:footer>
      <base-button type="primary" @mouseup="statusChngApicall"
        >Save changes</base-button
      >
      <base-button type="link" class="ml-auto" @click="statusModal = false"
        >Close
      </base-button>
    </template>
  </modal>
</template>
<script>
import Swal from "sweetalert2";
import pagination from "./pagination.vue"
import Papa from "papaparse";
import {
  invoice_list_api,
  invoiceStatusChng,
  invoiceFetch,
} from "../../api/invoice";

export default {
  name: "Invoice",
  components: {
    pagination
  },
  data() {
    return {
      last_status_id:'',
      pagination: {
        total: 0,
        default: 0,
        rows_per_page: 50,
        pageCount: 0,
      },
      status_chng: "",
      remark: "",
      status_array: [
        {
          id: 1,
          status_name: "Created",
        },
        {
          id: 2,
          status_name: "Need to Resubmit",
        },
        {
          id: 3,
          status_name: "Resubmitted",
        },
        {
          id: 4,
          status_name: "Processed",
        },
         {
          id: 5,
          status_name: "Partial Complete",
        },
         {
          id: 6,
          status_name: "Completed",
        },
      ],
      month_array:[{id:1,name:'Jan'},{id:2,name:'Feb'},{id:3,name:'Mar'},{id:4,name:'Apr'},{id:5,name:'May'},{id:6,name:'June'},{id:7,name:'July'},{id:8,name:'Aug'},{id:9,name:'Sept'},{id:10,name:'Oct'},{id:11,name:'Nov'},{id:12,name:'Dec'}],
      year_array: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      invoice_list: [],
      filter: [],
      filter_array: {},
      filter_field: [],
      search: {
        invoice_no: "",
        year: "",
        month: "",
        last_status: "",
       
      },
      employee_id:"",
      statusModal: false,
      user: "",
      invoice: [],
      bulkInvoiceList: [],
      flag:''
    };
  },
  mounted() {
    if(this.$route.params.emp_id){
this.employee_id =this.$route.params.emp_id
    }
    this.flag = localStorage.getItem("flag")
    this.user = JSON.parse(localStorage.getItem("userInfo")).role;
    this.fetchInvoiceList();
  },
  watch: {
    'pagination.default':function(){
    this.fetchInvoiceList();
    }
  },
  methods: {
    onpageChng (page) {
      this.pagination.default = page;
    },
    async addStatus() {
      if(this.status_chng == 4){
        this.remark = "Process";
      }else{
        this.remark = ""
      } 
    },
    async BulkdownloadInvoice() {
      //console.log(this.invoice_list);
      //this.bulkInvoiceList = this.invoice_list;
      const payload = {
        filter: [...this.filter_field],
        franchise_name: this.search.franchise_name,
      };
      if(this.employee_id){
        payload.filter.unshift({field_value:this.employee_id,field_name:'employee_id'})
      }
      const data = await invoice_list_api(payload);
      this.bulkInvoiceList = data.data.data.invoice
      //  const data = await invoiceFetch(id);
      // this.invoice = data.data.data.invoice.invoice_line_items;
      
      this.bulkInvoiceList.forEach((ele) => {
        delete ele.created_at;
       // delete ele.updated_at;
        delete ele.id;
      });
       this.bulkInvoiceList = this.bulkInvoiceList.map((element) => {
        switch (element.last_status) {
          case 1:
            return { ...element, status: "Created" };
          case 2:
            return { ...element, status: "Need to re-submit," };
          case 3:
            return { ...element, status: "Resubmitted" };
          case 4:
            return { ...element, status: "Processed" };
          case 5:
            return { ...element, status: "Partial Complete" };
             case 6:
            return { ...element, status: "Completed" };
          default:
            return { ...element };
        }
      });
      var i = 1;
     this.bulkInvoiceList = this.bulkInvoiceList.map((ele)=>{
          var x = {}
          let month_name = ''
           this.month_array.forEach((month)=>{
            if(month.id==ele.month){
              month_name = month.name
            } 
          })
          x['Sl No.'] = i++;
          x['Franchise/ASP Name'] = ele.asp_name;
           x['Location / Zones'] = ele.user.user_address?ele.user.user_address.zone:" ";
          x['Invoice No'] = ele.invoice_no;
          x['Date (Month & Year)'] = month_name+'-'+ele.year;
          x['Amount without Tax'] = ele.amount_payable - ele.gst;
          x['Tax'] = ele.gst;
          x['Gross Total'] = ele.amount_payable;
          x['Stage'] = ele.status;
           x['ERP Status (Date) (If Finished)'] = ele.last_status==6?ele.last_remarks:"";
           if(this.user == 'finance'){
              x['Account Number'] =ele.user.user_bank? ele.user.user_bank.account_no: "";
              x['IFSC CODE'] = ele.user.user_bank? ele.user.user_bank.ifsc_code:" ";
              x['BANK NAME'] = ele.user.user_bank? ele.user.user_bank.bank_name: " ";
              x['GST'] = ele.user.user_bank?ele.user.user_bank.gst: " ";
           }
          
           x['Remarks'] =ele.last_remarks;

          return x;
      })
      this.bULKdownload();
      console.log(this.bulkInvoiceList);
    },
    async resetFilter() {
      this.filter_field = [];
      this.fetchInvoiceList();
    },
    async bULKdownload() {
      let filename = "Bulk_Invoice.csv";
      let text = Papa.unparse(this.bulkInvoiceList);

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    async downloadInvoice(id) {
      const data = await invoiceFetch(id);
      this.invoice = data.data.data.invoice.invoice_line_items;
      this.month_array.forEach((month)=>{
            if(month.id==this.invoice[0].month){
              this.invoice.forEach(ele=>{
                ele.month = month.name
              })
            } 
          })
          this.invoice = this.invoice.map(ele=>{
            var x = {}
            x['Invoice Number'] = data.data.data.invoice.invoice_no;
            x['Month'] = ele.month;
            x['Year'] = ele.year;
            x['Asp Type']=ele.asp_type;
            x['Expenses Description']=ele.expense_desc
            x['Variable'] =ele.variable
            x['Fixed'] = ele.fixed
            return x;
          })
          //const len = this.invoice.length
          // console.log(this.invoice.length)
           this.invoice.push({'Expenses Description': 'GST','Fixed':data.data.data.invoice.gst})
           this.invoice.push({'Expenses Description': 'Without GST','Fixed':data.data.data.invoice.amount_payable-data.data.data.invoice.gst})
           this.invoice.push({'Expenses Description': 'Total Amount','Fixed':data.data.data.invoice.amount_payable})
      if(this.user == "finance"){
       this.invoice[0]["Account Number"] = data.data.data.invoice.user.user_bank.account_no
       this.invoice[0]["Bank Name"] = data.data.data.invoice.user.user_bank.bank_name
        this.invoice[0]["IFSC Code"] = data.data.data.invoice.user.user_bank.ifsc_code
        this.invoice[0]["GST"] = data.data.data.invoice.user.user_bank.gst
      }
     
      this.invoice.forEach((ele) => {
        delete ele.invoice_line_item_files;
        delete ele.id;
      });
      this.download();
    },
    download() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      let filename = "Invoice.csv";
      let text = Papa.unparse(this.invoice);

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    invoice_details_page(id) {
      this.$router.push({
        name: "invoice_details_page",
        params: { invoice_id:id },
      });
    },
    async editInvoice(id) {
      this.$router.push({
        name: "claim_edit",
        params: { invoice_id:id },
      });
    },
    async statusChngApicall() {
      try{
  const payload = {
        status: this.status_chng,
        remarks: this.remark,
      };
      const data = await invoiceStatusChng(this.status_id, payload);
      this.statusModal = false;
      this.fetchInvoiceList();
      Swal.fire({
        title: "Success",
        text: "Invoice Status Updated successfully",
        icon: "success",
      });
      this.status_chng = "";
      this.remark = "";
      console.log(data);
      } catch(e){
 Swal.fire({
        title: "warning",
        text: e.response.data.message,
        icon: "warning",
      });
      }
    
    },
    statusChng(id, status_id) {
      this.statusModal = true;
      this.status_id = id;
      this.last_status_id = status_id;
    },
    searchByFilter() {
      if (this.search.invoice_no != "") {
        const filter1 = {
          field_name: "invoice_no",
          field_value: this.search.invoice_no,
        };
        this.filter_field.push(filter1);
      }
      if (this.search.year != "") {
        const filter1 = {
          field_name: "year",
          field_value: this.search.year,
        };
        this.filter_field.push(filter1);
      }
      if (this.search.month != "") {
        const filter1 = {
          field_name: "month",
          field_value: this.search.month,
        };
        this.filter_field.push(filter1);
      }
      if (this.search.last_status != "") {
        const filter1 = {
          field_name: "last_status",
          field_value: this.search.last_status,
        };
        this.filter_field.push(filter1);
      }
      this.fetchInvoiceList();
      console.log(this.filter_field);
    },

    async fetchInvoiceList() {
      const payload = {
        filter: [...this.filter_field],
        franchise_name: this.search.franchise_name,
      };
      if(this.employee_id){
        payload.filter.unshift({field_value:this.employee_id,field_name:'employee_id'})
      }
      const offset = this.pagination.default * this.pagination.rows_per_page;
      const limit = this.pagination.rows_per_page;
      const list = await invoice_list_api(payload,offset,limit);
      this.invoice_list = list.data.data.invoice;
      this.pagination.total = list.data.data.count;
      this.invoice_list = this.invoice_list.map((element) => {
        switch (element.last_status) {
          case 1:
            return { ...element, status: "Created" };
          case 2:
            return { ...element, status: "Need to re-submit," };
          case 3:
            return { ...element, status: "Resubmitted" };
          case 4:
            return { ...element, status: "Processed" };
          case 5:
            return { ...element, status: "Partial Complete" };
            case 6:
            return { ...element, status: "Completed" };
          default:
            return { ...element };
        }
      });
      //console.log();
    },
  },
};
</script>
<style></style>
