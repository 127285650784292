<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>
    <div class="card mt-4">
      <div class="container">
        <h4>Filter</h4>
        <div class="row">
          <div class="col-lg-3">
            <base-input>
              <input
                type="text"
                placeholder="ASP Name"
                class="form-control form-control-alternative"
                v-model="search.franchise_name"
              />
            </base-input>
          </div>
          <div class="col-lg-3">
            <base-input>
               <select class="select_input" v-model="search.role">
                  <option value="" disabled selected>Select Service centre type</option>
                      <option value="sa">SA</option>
                      <option value="ba">BA</option>
                      <option value="hybrid">HYBRID</option>
                      <option value="ssd">SSD</option>
                    </select>
            </base-input>
          </div>
          <div class="col-lg-2">
            <base-input>
               <select class="select_input" v-model="search.zone">
                 <option value="" disabled selected>Select Zone</option>
                  <option v-for="zone in zone_list" :key="zone" :value="zone.zone">{{zone.zone}}</option>
                </select>
            </base-input>
          </div>
          <div class="col-lg-2">
            <base-button type="success" @mouseup="searchByFilter"
              ><i class="fa fa-search"></i
            ></base-button>
            <button
              @click="resetFilter"
              type="button"
              class="btn btn-secondary"
            >
              <i class="fa fa-undo"></i>
            </button>
          </div>
          <div class="col-lg-2">
            <base-button type="success" @click="$router.push('/create_user')"
              ><i class="fa fa-plus"></i> Add ASP</base-button
            >
          </div>
        </div>

        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="asp_list">
            <template v-slot:columns>
              <th>ASP Name</th>
              <th>Type of Service Center</th>
              <th>Zone</th>
              <th>Action</th>
            </template>

            <template v-slot:default="row">
              <td scope="row">
                {{ row.item.franchise_name }}
              </td>
              <td>
                {{ row.item.role }}
              </td>
              <td>
                {{
                  row.item.user_address != null
                    ? row.item.user_address["zone"]
                    : ""
                }}
              </td>
              <td>
                <base-button
                  @click="editAsp(row.item.id)"
                  icon="fas fa-pen"
                  size="sm"
                  type="success"
                ></base-button>

                <!-- <base-button
                  @mouseup="deleteAsp(row.item.id)"
                  icon="fas fa-trash"
                  type="danger"
                  size="sm"
                ></base-button>  -->
                <Toggle
                  v-model="row.item.is_activated"
                  @mouseup="defToggle(row.item.id)"
                />
              </td>
            </template>
          </base-table>
          <pagination :total_Count="pagination.total" :current_page="pagination.default" :onpageChng="onpageChng" :rows_per_page="pagination.rows_per_page"></pagination>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="deactivateMOdal">
    <template v-slot:header>
      <h5 class="modal-title" id="exampleModalLabel">ASP Deactivated Remark</h5>
    </template>
    <div>
      <label>Remark</label>
      <input
        type="text"
        placeholder="Remark"
        class="form-control form-control-alternative"
        v-model="remark"
                  />
    </div>
    <template v-slot:footer>
      <base-button type="secondary" @mouseup="closeModal"
        >Close</base-button
      >
      <base-button @mouseup="deactivateAsp" type="primary">Save changes</base-button>
    </template>
  </modal>
</template>
<script>
import pagination from "./Dashboard/pagination.vue"
import Toggle from "@vueform/toggle";
import Swal from "sweetalert2";
import { Asp_List_Api, asp_delete, ASP_Deactivate, ASP_Reactivate,fetchZoneListApi } from "../api/user";
export default {
  components: {
    Toggle,
    pagination
  },
  name: "page-visits-table",
  data() {
    return {
       pagination: {
        total: 0,
        default: 0,
        rows_per_page: 50,
        pageCount: 0,
      },
      asp_list: [],
      search: {
        franchise_name: "",
        role: "",
        zone: "",
      },
      deactivateMOdal: false,
      deactivate_id:null,
      remark:'',
      zone_list:[]
    };
  },
  created() {
    this.fetchAspList();
    this.fetchZoneList()
  },
    watch: {
    'pagination.default':function(){
    this.fetchAspList();
    }
  },
  methods: {
     async fetchZoneList(){
          const data = await fetchZoneListApi()
          this.zone_list = data.data.data.zone
          console.log(data)
      },
    onpageChng (page) {
      this.pagination.default = page;
    },
    async deactivateAsp(){
      try{
         const payload = {
        remarks:this.remark,
      }
      const data = await ASP_Deactivate(this.deactivate_id,payload);
      console.log(data)
      this.deactivateMOdal = false
       Swal.fire({
        title: "Success",
        text: "ASP Deactivated successfully",
        icon: "success",
      });
      this.remark = ""
      } catch (e) {
         Swal.fire({
        title: "warning",
        text: "Something went wrong",
        icon: "warning",
      });
      }

    },
    async closeModal(){
      this.deactivateMOdal = false
     
      let i = this.asp_list.findIndex(ele=> ele.id==this.deactivate_id)
      this.asp_list[i].is_activated = true
       console.log( this.asp_list[i].is_activated)
    }
    ,
    async defToggle(index) {
      this.deactivate_id = index;
      console.log(this.deactivate_id,index)
      let i = this.asp_list.findIndex(ele=> ele.id==index)
      if(this.asp_list[i].is_activated == true) {
        this.deactivateMOdal = true;
      } else {
        const data = await ASP_Reactivate(this.deactivate_id)
 Swal.fire({
        title: "Success",
        text: "ASP Activated successfully",
        icon: "success",
      });
      console.log(data)
      }
      
    },
    resetFilter() {
      this.search.franchise_name = "";
      (this.search.zone = ""), (this.search.role = ""), this.fetchAspList();
    },
    // pagechng(e){
    //   this.pagination.default = parseInt(e.target.innerText);
    //   console.log(e)
    // },
    async deleteAsp(id) {
      const data = await asp_delete(id);
      this.fetchAspList();
      Swal.fire({
        title: "Success",
        text: "ASP deleted successfully",
        icon: "success",
      });
      console.log(data);
    },
    async editAsp(value) {
      this.$router.push({
        name: "profile_edit",
        params: { asp_id:value },
      });
    },
    async searchByFilter() {
      this.fetchAspList();
    },
    async fetchAspList() {
      const payload = { ...this.search };
     const offset = this.pagination.default * this.pagination.rows_per_page;
      const limit = this.pagination.rows_per_page;
      const data = await Asp_List_Api(payload,offset,limit);
      this.asp_list = data.data.data.user;
      this.pagination.total = data.data.data.count;
      // this.pagination.pageCount = Math.ceil( parseInt( data.data.data.count) / this.pagination.rows_per_page)
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
