// axios
import axios from "axios";
// import store from "./store/store";

// let baseURL = ''
// if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
//
//   baseURL = 'http://127.0.0.1:8000/api'
// } else {
//   baseURL = 'http://scoolio-backend-dev.track-progress.com/api'
//
// }

const baseURL = process.env.VUE_APP_API_URL;

//You can add your headers here
const service = axios.create({
  baseURL, // api base_url
  timeout: 60000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {

      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    // for debug
    Promise.reject(error);
  }
);
// service.interceptors.response.use(
//   (response) => {
//     const environment = response.headers["x-server"] || "production";

//     // no need to repeatedly set this with every api request. doing it the first time is enough.
//     if (!store.getters.environment) {
//       // store.commit('SET_ENVIRONMENT', environment)
//     }
//     return response;
//   },
//   (error) => {
//     let message = "";

//     // authentication handling
//     if (error.response && error.response.status === 401) {
//       store.dispatch("LocalLogout").then(() => {
//         location.reload();
//       });
//     }

//     if (error.response && error.response.data && error.response.data.message) {
//       message = error.response.data.message;
//     } else {
//       message = error.response.data.error;
//     }
//     // if (error) {
//     //   message = error
//     // }

//     // Notify(message)
//     return Promise.reject(error.response);
//   }
// );

export default service;
