<template>
  <div>
    <div v-if="asp_type == 'admin'">
      <countTable></countTable>
    </div>
    <div v-else-if="asp_type == 'service_hod'">
      <employee_list></employee_list>
    </div>
    <div v-else>
      <page-visits-table></page-visits-table>
    </div>

    <!--End tables-->
  </div>
</template>
<script>
// Charts
import Chart from "chart.js";

import PageVisitsTable from "./Dashboard/PageVisitsTable";
import countTable from "./Dashboard/CountTable";
import employee_list from "./emp_list.vue"
// import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
let chart;

export default {
  components: {
    PageVisitsTable,
    countTable,
    employee_list
    // SocialTrafficTable,
  },
  data() {
    return {
      asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
    };
  },
  methods: {
    initBigChart(index) {
      chart.destroy();
      chart = new Chart(
        document.getElementById(this.salesChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
              {
                label: "Performance",
                tension: 0.4,
                borderWidth: 4,
                borderColor: "#5e72e4",
                pointRadius: 0,
                backgroundColor: "transparent",
                data: this.bigLineChart.allData[index],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
      this.bigLineChart.activeIndex = index;
    },
  },
  // mounted() {
  //   chart = new Chart(
  //     document.getElementById(this.salesChartID).getContext("2d"),
  //     {
  //       type: "line",
  //       data: {
  //         labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //         datasets: [
  //           {
  //             label: "Performance",
  //             tension: 0.4,
  //             borderWidth: 4,
  //             borderColor: "#5e72e4",
  //             pointRadius: 0,
  //             backgroundColor: "transparent",
  //             data: this.bigLineChart.allData[1],
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: true,
  //         maintainAspectRatio: false,
  //         legend: {
  //           display: false,
  //         },
  //         tooltips: {
  //           enabled: true,
  //           mode: "index",
  //           intersect: false,
  //         },
  //         scales: {
  //           yAxes: [
  //             {
  //               barPercentage: 1.6,
  //               gridLines: {
  //                 drawBorder: false,
  //                 color: "rgba(29,140,248,0.0)",
  //                 zeroLineColor: "transparent",
  //               },
  //               ticks: {
  //                 padding: 0,
  //                 fontColor: "#8898aa",
  //                 fontSize: 13,
  //                 fontFamily: "Open Sans",
  //               },
  //             },
  //           ],
  //           xAxes: [
  //             {
  //               barPercentage: 1.6,
  //               gridLines: {
  //                 drawBorder: false,
  //                 color: "rgba(29,140,248,0.0)",
  //                 zeroLineColor: "transparent",
  //               },
  //               ticks: {
  //                 padding: 10,
  //                 fontColor: "#8898aa",
  //                 fontSize: 13,
  //                 fontFamily: "Open Sans",
  //               },
  //             },
  //           ],
  //         },
  //         layout: {
  //           padding: 0,
  //         },
  //       },
  //     }
  //   );
  //   ordersChart.createChart(this.ordersChartID);
  // },
};
</script>
<style></style>
