<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <form role="form">
            <div
              class="form-group input-group-alternative mb-3"
              style="position: relative; border: 2px solid rgb(0, 96, 170)"
            >
              <i
                class="fa fa-user"
                style="
                  position: absolute;
                  top: 14px;
                  left: 8px;
                  display: block;
                  color: rgb(0, 96, 170);
                "
              ></i>
              <input
                type="text"
                placeholder="User Name"
                class="form-control form-control-alternative"
                style="padding-left: 30px"
                v-model="user_name"
              />
            </div>

            <div
              class="form-group input-group-alternative mb-3"
              style="position: relative; border: 2px solid rgb(0, 96, 170)"
            >
              <i
                class="fa fa-lock"
                style="
                  position: absolute;
                  top: 14px;
                  left: 8px;
                  display: block;
                  color: #rgb(0, 96, 170);
                "
              ></i>
              <input
                type="password"
                placeholder="Password"
                class="form-control form-control-alternative"
                style="padding-left: 30px"
                v-model="password"
              />
            </div>

            <!-- <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox> -->
            <div class="text-center">
              <base-button
                type="primary"
                @mouseup="loginJwt"
                class="my-4 btn-block"
                >Sign in</base-button
              >
            </div>
          </form>
          <base-alert v-if="msg=='wrong'" type="danger" dismissible>
             <strong>Wrong!</strong> Wrong Username or Password!
          </base-alert>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/forget_password" class="text-light"><small>Forgot password?</small></router-link>
        </div> 
        <!-- <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>-->
      </div> 
    </div>
  </div>
</template>
<script>
// import { token } from "../router/index";
import Swal from "sweetalert2";
import { Login } from "@/api/login";
// import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      user_name: "",
      password: "",
      msg:'',
    };
  },
  methods: {
    ...mapActions(["SET_USER_INFO"]),
    async loginJwt() {
      const payload = {
        user_name: this.user_name,
        password: this.password,
      };
      const data = await Login(payload);
      console.log(data.data.data);
      if (data.data.data.token) {
        // this.$vs.loading();

        localStorage.setItem("token", data.data.data.token);
localStorage.setItem("flag", data.data.data.flag);
        localStorage.setItem("userInfo", JSON.stringify(data.data.data.user));
        // token.token = data.data.data.token;
        Swal.fire({
          title: "Success",
          text: "Login successfully",
          icon: "success",
        });
if(data.data.data.flag == 'asp'){
  if(data.data.data.user.is_first_login){
this.$router.push("/first_password").catch(() => {});
  }
  else{
this.$router.push("/dashboard").catch(() => {});
  }
}else {
  this.$router.push("/dashboard").catch(() => {});
}
        
      } else {
        this.msg = 'wrong'
        // Swal.fire({
        //   title: "Wrong",
        //   text: "Wrong Username or Password",
        //   icon: "warning",
        // });
      }
    },
  },
};
</script>
<style scoped>
.btn-primary,
.btn-primary:hover {
  color: #fff;
  background-color: rgb(0, 96, 170);
  border-color: rgb(0, 96, 170);
}
</style>
