import request from "@/axios";

export function Login(query) {
  //
  return request({
    url: "/auth/login",
    method: "POST",
    data: query,
  });
}

export function ForgetPassword(query) {
  //
  return request({
    url: "/auth/forget_password",
    method: "POST",
    data: query,
  });
}

export function validatePassword(query) {
  //
  return request({
    url: "/auth/check_verification",
    method: "POST",
    data: query,
  });
}

export function ResetPassword(query) {
  //
  return request({
    url: "/auth/reset_password",
    method: "POST",
    data: query,
  });
}