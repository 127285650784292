<template>
    <div>
     <base-button :disabled="current_page == 0"   @click="onpageChng(current_page-1)" type="primary" size="sm" icon="ni ni-bold-left"></base-button>
   <base-button :disabled="page.page_no == current_page" type="primary" @click="onpageChng(page.page_no)" size="sm" v-for="(page,index) in pages" :key="index">{{page.page_no_label}}</base-button>
     <base-button :disabled="current_page == pages.length-1" @click="onpageChng(current_page+1)" type="primary" size="sm" icon="ni ni-bold-right"></base-button>
    </div>
</template>
<script>

export default {
props:{
    onpageChng:{
        type: Function,
    },
    total_Count:{
        default: 0,
    },
    rows_per_page:{
        default:0
    },
    current_page:{
        default:0
    }
},
computed: {
    pages:function(){
        const no_of_pages = Math.ceil(this.total_Count / this.rows_per_page)
        const pages = []
        for(var i = 0; i < no_of_pages; i++){
            pages.push({page_no:i,page_no_label:i+1})
        }
        return pages
    }
}
}
</script>

<style>

</style>