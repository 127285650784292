import request from "../axios";

export function CreateInvoice(query) {
  //
  return request({
    url: "invoice/create",
    method: "POST",
    data: query,
  });
}

export function invoice_list_api(payload,offset=null,limit=null) {
  var url= offset==null && limit==null?"invoice/list": `invoice/list?offset=${offset}&limit=${limit}`
  return request({
    url:url,
    method: "POST",
    data: payload,
  });
}

export function UploadToAwsApi(file_payload) {
  return request({
    url: "upload/uploadToAws",
    method: "POST",
    data: file_payload,
  });
}

export function fetchFileID(file_payload) {
  return request({
    url: "upload/uploadToDB",
    method: "POST",
    data: file_payload,
  });
}

export function invoiceUpdate(payload, id) {
  return request({
    url: `invoice/update/${id}`,
    method: "POST",
    data: payload,
  });
}
export function invoiceFetch(id) {
  return request({
    url: `invoice/details/${id}`,
    method: "GET",
  });
}
export function invoiceStatusChng(id, payload) {
  return request({
    url: `invoice/update/status/${id}`,
    method: "POST",
    data: payload,
  });
}

