<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <div class="row">
                <div class="col-lg-3">
                  <base-input label="Invoice Number">
                    <input
                      disabled
                      type="text"
                      class="form-control form-control-alternative"
                      v-model="invoice_no"
                    />
                  </base-input>
                </div>
                <div class="col-lg-3">
                  <label class="form-control-lebel">Month</label><br />
                  <select
                    style="padding: 0.25rem"
                    disabled
                    class="select_input_month"
                    v-model="month"
                  >
                    <option :value="i.id" v-for="i in month_array" :key="i">
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3">
                  <label class="form-control-lebel">Year</label><br />
                  <select
                    disabled
                    style="padding: 0.25rem"
                    class="select_input_month"
                    v-model="year"
                  >
                    <option
                      :value="year"
                      v-for="year in year_array"
                      :key="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3">
                  <base-input label="RSM Name">
                    <input
                      disabled
                      type="text"
                      placeholder="RSM Name"
                      class="form-control form-control-alternative"
                      v-model="rem_name"
                    />
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <span>Expenses Name</span>
                </div>
                <div class="col-lg-1">
                  <span>Month</span>
                </div>
                <div class="col-lg-1 px-1">
                  <span>Year</span>
                </div>
                <div class="col-lg-2">
                  <span>Fixed</span>
                </div>
                <div class="col-lg-2">
                  <span>Variable</span>
                </div>
              </div>
              <!-- {{ expenses_list }} -->
              <div
                class="row mt-2"
                v-for="(item, index) of expenses_list"
                :key="index"
              >
                <div class="col-lg-3">
                  <select
                    disabled
                    class="select_input form-control"
                    v-model="item.expense_desc"
                  >
                    <option value="remunaration">Remunaration</option>
                    <option value="AMC Commision">AMC Commision</option>
                    <option value="Approved Travel Expenses">
                      Approved Travel Expenses
                    </option>
                    <option value="audi">Approved Spare Purchase</option>
                    <option value="Approved Spare Purchase">
                      Approved Spare Purchase
                    </option>
                    <option value="Outside Repaired Claim">
                      Outside Repaired Claim
                    </option>
                    <option value="Approved Other Expenses">
                      Approved Other Expenses
                    </option>
                    <option value="Royality Deduction">
                      Royality Deduction
                    </option>
                  </select>
                </div>
                <div class="col-lg-1">
                  <select
                    style="padding: 0.25rem"
                    disabled
                    class="select_input form-control"
                    v-model="item.month"
                  >
                    <option :value="i.id" v-for="i in month_array" :key="i">
                      {{ i.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-1 px-1">
                  <select
                    disabled
                    class="select_input form-control"
                    v-model="item.year"
                    style="padding: 0.25rem"
                  >
                    <option :value="i" v-for="i in year_array" :key="i">
                      {{ i }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-2 px-2">
                  <!-- <input
                    v-if="item.expense_desc == 'remunaration'"
                    type="number"
                    placeholder="Fixed"
                    class="form-control form-control-alternative"
                    v-model="item.fixed"
                    :disabled="true"
                    style="padding:0.25rem"
                  /> -->
                  <input
                   :disabled="true"
                    style="padding: 0 13px 0 12px"
                    v-if="item.expense_desc == 'remunaration'"
                    @blur="preview"
                    type="number"
                    placeholder="Fixed"
                    class="form-control form-control-alternative"
                    v-model="item.fixed"
                  />
                  <input
                    v-else
                    type="number"
                    placeholder="N/A"
                    class="form-control form-control-alternative"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2">
                  <input
                    style="padding: 0px 20px 0px 20px"
                    disabled
                    @blur="FindtotalVariable(index)"
                    type="number"
                    placeholder="Variable"
                    class="form-control form-control-alternative"
                    v-model="item.variable"
                  />
                </div>

                <div
                  class="col-lg-2"
                  v-if="
                    item.expense_desc == 'Approved Travel Expenses' ||
                    item.expense_desc == 'Approved Spare Purchase' ||
                    item.expense_desc == 'Outside Repaired Claim' ||
                    item.expense_desc == 'Approved Other Expenses'
                  "
                >
                  <!-- {{ item.invoice_line_item_files[0].file.file_path }} -->
                  <a
                    :href="item.invoice_line_item_files[0].file.file_path"
                    download
                    target="_blank"
                    >View File</a
                  >

                  <!--  <div class="text" @click="downloadImage(file)" v-else-if="file.file_type === 'text/plain'">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-word" class="svg-inline--fa fa-file-word fa-w-12" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"/></svg>
                  </div>

                  <div class="pdf" @click="downloadImage(file)"  v-else-if="file.file_type === 'application/pdf'">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-pdf" class="svg-inline--fa fa-file-pdf fa-w-12" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"/></svg>
                  </div>

                  <div class="excel" @click="downloadImage(file)" v-else-if="file.file_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-excel" class="svg-inline--fa fa-file-excel fa-w-12" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"/></svg>
                  </div>

                  <div class="text" @click="downloadImage(file)" v-else-if="file.file_type === 'video/mp4'">
                    <video class="cstm-card-img" :src="item.file_path"></video>
                  </div>

                  <div class="general" @click="downloadImage(file)" v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-alt" class="svg-inline--fa fa-file-alt fa-w-12" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"/></svg>
                  </div> -->
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-2">
                  <label class="gst">GST@18%</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-2"></div>
                <div class="col-lg-2">
                  <input
                    type="number"
                    placeholder="Total Variable"
                    class="form-control form-control-alternative"
                    v-model="total_gst"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2"></div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-4">
                  <label>Amount without 18% gst</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-2">
                  <input
                    type="number"
                    placeholder="Total Variable"
                    class="form-control form-control-alternative"
                    v-model="total_amt"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2"></div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-2">
                  <label>Amount Payable</label>
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-2">
                  <input
                    type="number"
                    placeholder="Total Amount"
                    class="form-control form-control-alternative"
                    v-model="total_amt_payable"
                    :disabled="true"
                  />
                </div>
                <!-- <div class="col-lg-2">
                  <input
                    disabled
                    name="file.file_name"
                    ref="file"
                    type="file"
                    placeholder="File"
                    class="form-control form-control-alternative"
                  />
                </div> -->
                <div class="col-lg-1 mt-1">
                  <a :href="file.file_path" download target="_blank"
                    >View File</a
                  >
                </div>
              </div>
              <div class="row text-right pt-4">
                <div class="col-lg-6 text-right">
                  <!-- <base-button type="info" @mouseup="preview"
                    >Preview
                  </base-button> -->
                  <base-button
                    class="btn btn-primary"
                    @mouseup="
                      $router.push({
                        name: 'dashboard',
                      })
                    "
                    >Back</base-button
                  >
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Datepicker from "vue3-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import {
  CreateInvoice,
  UploadToAwsApi,
  fetchFileID,
  invoiceFetch,
  invoiceUpdate,
} from "../api/invoice";

export default {
  name: "claim",
  mounted() {
    this.fetchINvoiceDetails(this.$route.params.invoice_id);
  },
  data() {
    return {
      month_array: [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sept" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
      ],
      year_array: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      total_amt_payable: 0,
      date: "",
      invoice_no: "",
      month: "",
      year: "",
      rem_name: "",
      file: [],
      total_gst: 0,
      add_expenses_form: true,
      expenses_list: [
        {
          expense_desc: "",
          month: "",
          year: "",
          fixed: 0,
          variable: 0,
          invoice_line_item_files: [],
        },
      ],
      datafile: "",
      invoice_id: "",
      file_id: "",
      confirm_accountNumber: "",
      error_text: false,
      asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
      gst_row: false,
      total_amt: 0,
    };
  },
  methods: {
    async preview() {
      this.gst_row = !this.gst_row;
    },
    async UpdateInvoice() {
      const payload = {
        invoice_no: this.invoice_no,
        month: this.month,
        year: this.year,
        asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
        rem_name: this.rem_name,
        invoice_line_items: this.expenses_list,
      };
      const data = await invoiceUpdate(payload, this.invoice_id);
      console.log(data);
    },
    async fetchINvoiceDetails(id) {
      const data = await invoiceFetch(id);
      const invoiceDet = data.data.data.invoice;
      this.invoice_id = id;
      this.invoice_no = invoiceDet.invoice_no;
      this.month = invoiceDet.month;
      this.year = invoiceDet.year;
      this.rem_name = invoiceDet.rem_name;
      this.expenses_list = invoiceDet.invoice_line_items;
      this.total_gst = invoiceDet.gst;
      this.total_amt_payable = invoiceDet.amount_payable;
      this.total_amt = this.total_amt_payable - this.total_gst;
      this.file = invoiceDet.file;
    },
    async uploadToDb(index) {
      const fetchPayload = {
        file_name: this.datafile.file_name,
        file_path: this.datafile.url,
      };
      const data = await fetchFileID(fetchPayload);
      console.log(data.data.data.file);
      this.file_id = data.data.data.file;
      // this.file_id = this.file_id.filter((element) => {
      //   return element.id;
      // });

      const uploadFile_id = { file_id: this.file_id.id };
      console.log(uploadFile_id, index);
      this.expenses_list[index].invoice_line_item_files.push(uploadFile_id);
    },
    async uploadToAws() {
      const file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("file", file);
      const payload = formData;
      const { data } = await UploadToAwsApi(payload);
      this.datafile = data.data;
    },
    remove(index) {
      this.expenses_list.splice(index, 1);
    },
    fixedPay(index) {
      if (this.expenses_list[index].expense_desc == "remunaration") {
        if (this.asp_type == "SA") {
          this.expenses_list[index].fixed = 5000;
        } else if (this.asp_type == "BA") {
          this.expenses_list[index].fixed = 1000;
        } else if (this.asp_type == "HYBRID") {
          this.expenses_list[index].fixed = 1000;
        } else if (this.asp_type == "SSD") {
          this.expenses_list[index].fixed = 1000;
        }
      }
    },
    FindtotalVariable(index) {
      console.log(this.expenses_list[index]);
      if (this.expenses_list[index].expense_desc === "Royality Deduction") {
        this.total_variable =
          this.total_variable - this.expenses_list[index].variable;
      } else {
        this.total_variable =
          this.total_variable + this.expenses_list[index].variable;
      }
    },
    addField() {
      const payload = {
        expense_desc: "",
        month: "",
        year: "",
        fixed: 0,
        variable: 0,
        invoice_line_item_files: [],
      };
      this.expenses_list.push(payload);
    },
    downloadItem(item) {
      try {
        const response = axios({
          url: `${item.file_path}`,
          method: "GET",
          responseType: "blob",
          headers: { "Access-Control-Allow-Origin": "*" },
        });

        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;

        fileLink.setAttribute("download", item.file_name);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch {
        console.error;
      }
    },
    invoiceCreate() {
      try {
        const payload = {
          invoice_no: this.invoice_no,
          month: this.month,
          year: this.year,
          asp_type: JSON.parse(localStorage.getItem("userInfo")).role,
          rem_name: this.rem_name,
          invoice_line_items: this.expenses_list,
        };
        console.log(payload);
        const data = CreateInvoice(payload);
        console.log(data);
        if (data) {
          Swal.fire({
            title: "Success",
            text: "Invoice created successfully",
            icon: "success",
          });
        }
      } catch (e) {
        console.log(e.error);
      }
    },
  },
};
</script>
<style>
.download-img {
  width: 20px;
  height: 25px;
}
.error_txt {
  color: red;
  font-size: 12px;
}
.gst {
  color: blue;
  padding: 2px;
}
.select_input_month {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
  padding: 0.625rem 0.5rem;
  width: 100;
}
</style>
