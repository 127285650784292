<template>
  <div>
    <base-header class="header pt-lg-6 d-flex align-items-center">
      <span class="mask bg_color opacity-8"></span>
    </base-header>
    <div class="container">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h6 class="heading-small text-muted mb-4">User information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      required
                      label="User Name"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.user_name.$error"
                      >
                        &nbsp;{{ v$.model.user_name.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="User Name"
                        class="form-control form-control-alternative"
                        v-model="model.user_name"
                      />
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      required
                      label="Email"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.email.$error"
                      >
                        &nbsp;{{ v$.model.email.$errors[0].$message }}
                      </span>
                      <input
                        type="email"
                        placeholder="Email"
                        class="form-control form-control-alternative"
                        v-model="model.email"
                      />
                    </base-input>
                  </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                    <base-input
                      required
                      label="Employee Code"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.emp_code.$error"
                      >
                        &nbsp;{{ v$.model.emp_code.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="Employee Code"
                        class="form-control form-control-alternative"
                        v-model="model.emp_code"
                      />
                    </base-input>
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      required
                      label="Name"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.model.name.$error"
                      >
                        &nbsp;{{ v$.model.name.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="Name"
                        class="form-control form-control-alternative"
                        v-model="model.name"
                      />
                    </base-input>
                  </div>
                  <div class="col-lg-4">
                    <label class="required">Role</label><br />
                    <span
                      class="validation-msg"
                      v-if="v$.model.user_name.$error"
                    >
                      &nbsp;{{ v$.model.user_name.$errors[0].$message }}
                    </span>
                    <select :disabled="form_type=='Edit'" class="select_input" v-model="model.role">
                      <option value="service_hod">Service Head</option>
                      <option value="hod">Regional Head</option>
                      
                      <option value="finance">Finance</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                     <div class="col-lg-6" v-if="model.role == 'hod'">
                    <label>Zone</label><br />
                    <select  multiple class="select_input" v-model="employee_zones">
                      <option v-for="zone in zone_list" :key="zone" :value="zone.id">{{zone.zone}}</option>
                    </select>
                  </div>
                    <div class="col-lg-6">
                    <base-input v-if="!user_id"
                      required
                      label="Password"
                      input-classes="form-control-alternative"
                    >
                      <span
                        class="validation-msg"
                        v-if="v$.password.$error && !user_id"
                      >
                        &nbsp;{{ v$.password.$errors[0].$message }}
                      </span>
                      <input
                        type="text"
                        placeholder="Password"
                        class="form-control form-control-alternative"
                        v-model="password"
                      />
                    </base-input>
                  </div>
                 
                </div>
              </div>
              <div class="row text-right">
                <div class="col-lg-6 text-right">
                  <base-button
                    @mouseup="addUser"
                    type="success"
                    v-if="form_type == 'create'"
                    >Submit</base-button
                  >
                  <base-button @mouseup="updateUser" type="success" v-else
                    >Update</base-button
                  >
                  <base-button
                    type="danger"
                    @mouseup="
                      $router.push({
                        name: 'emp list',
                      })
                    "
                    >Cancel</base-button
                  >
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AddEmpApi, fetchSpecEmp, UpdateEmpApi,fetchZoneListApi } from "../api/user";
export default {
  name: "Employee",
  mounted() {
      this.fetchZoneList()
    if (this.$route.params.emp_id) {
      this.fetchUserDEtails(this.$route.params.emp_id);
      this.form_type = "Edit";
    }
  },
  validations() {
    return {
        password: { required },
      model: {
        user_name: { required },
        emp_code:{ required},
        name: { required },
        email: { required },
        role: { required },

      },
    };
  },
  data() {
    return {
      v$: useValidate(),
      user_id: "",
      form_type: "create",
      zone_list:[],
      password:'',
      model: {
        user_name: "",
        role: "",
        email:'',
        name:'',
        emp_code:''
        
      },
      employee_zones:[]
    };
  },
  methods: {
      async fetchZoneList(){
          const data = await fetchZoneListApi()
          this.zone_list = data.data.data.zone
          console.log(data)
      },
    async updateUser() {
      try{
const payload = {
        ...this.model,
        employee_zones:this.employee_zones,
      };
      const data = await UpdateEmpApi(payload,this.user_id);
      console.log(data);
      Swal.fire({
        title: "Success",
        text: "Employee updated successfully",
        icon: "success",
      });
      this.$router.push({ name: "emp list" });
      } catch (e) {
         Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
      }
      
    },
    async fetchUserDEtails(id) {
      const response = await fetchSpecEmp(id);
      const userDetails = response.data.data.employee;
      console.log(response.data.data.user);
      this.user_id = id;
      this.model.emp_code = userDetails.emp_code;
      this.model.user_name = userDetails.user_name;
      this.model.role = userDetails.role;
      this.model.email = userDetails.email;
      this.model.name = userDetails.name;
      this.employee_zones = userDetails.employee_zones.map(element=>{
          return element.zone_id
      });
    },
    async addUser() {
      try {
        this.v$.$validate(); // checks all inputs
        if (!this.v$.$error) {
          // if ANY fail validation
          const payload = {
            ...this.model,
            employee_zones:this.employee_zones,
            password:this.password,
          };
          const { data } = await AddEmpApi(payload);
          console.log(data);
          Swal.fire({
            title: "Success",
            text: "Employee created successfully",
            icon: "success",
          });
         this.$router.push({ name: "emp list" });
        } else {
          Swal.fire({
            title: "Error",
            text: "Validation failed",
            icon: "warning",
          });
        }
      } catch (e) {
        Swal.fire({
          title: "Error",
          text: e.response.data.message,
          icon: "warning",
        });
        console.log(e.response.data.message);
      }
    },
  },
};
</script>
<style>
.select_input {
  padding: .625rem .50rem;
  width: 100;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;

}
.validation-msg {
  color: red;
}
</style>
